import React from 'react';
import { graphql, Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Layout from '../components/layout';
import Img from 'gatsby-image';
import ImageZoom from 'react-medium-image-zoom';
import ScrollAnimation from 'react-animate-on-scroll';
import Header from '../components/header';
import Footer from '../components/footer';
import Contact from '../components/contact';
import SEO from "../components/seo";

const ProductsPage = ({ data }) => (
  <Layout>
    <SEO title="產品應用" />
    <Header />
    <Contact/>

    <section className="product-banner">
      <div className="banner-second-main">
        <div className="container in-banner">
          <div className="flex-row make-verical w-row">
              <div className="hero-text-box">
                <h1 className="main-banner-title">炫目<br />源於光彩萬變</h1>
              </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section page-content">
      <div className="w-container">
        <div className="w-col w-col-12 w-col-stack">
          <ScrollAnimation
          animateIn='fadeInUp'
          animateOnce={true}
          >
          {data.allStrapiProduct.edges.map(document => (
            <div key={document.node.id}> 
              {(document.node.language === "Chinese" && document.node.label === "Intro" ) ?
              <div>
                <ReactMarkdown source={document.node.description} />
              </div> : <span />}
            </div>
          ))}
          </ScrollAnimation>
        </div>
      </div>
    </section>
    <section className="page-content" style={{ background:"#232323", paddingTop:"100px"}}>
      <div className="w-container">
        <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
          <div className="w-row">
            <div className="w-col w-col-6 w-col-stack">
              {data.allStrapiProduct.edges.map(document => (
                <div key={document.node.id}> 
                  {(document.node.language === "English" && document.node.label === "FilmAndPaper" ) ?
                    <Img fluid={document.node.cover.childImageSharp.fluid} /> : <span />
                  }
                </div>
              ))}
            </div>
            <div className="w-col w-col-6 w-col-stack">
              {data.allStrapiProduct.edges.map(document => (
                <div key={document.node.id}> 
                  {(document.node.language === "Chinese" && document.node.label === "FilmAndPaper" ) ?
                  <div style={{ color: "#8c8c8c" }}>
                    <ReactMarkdown source={document.node.description} />
                  </div> : <span />}
                </div>
              ))}
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </section>
    <section className="page-content" style={{ background:"#232323"}}>
      <div className="w-container">
        <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
          <div className="w-row">
            <div className="w-col w-col-6 w-col-stack">
              {data.allStrapiProduct.edges.map(document => (
                <div key={document.node.id}> 
                  {(document.node.language === "Chinese" && document.node.label === "Foil" ) ?
                  <div style={{ color: "#8c8c8c" }}>
                    <ReactMarkdown source={document.node.description} />
                  </div> : <span />}
                </div>
              ))}
            </div>
            <div className="w-col w-col-6 w-col-stack">
              {data.allStrapiProduct.edges.map(document => (
                <div key={document.node.id} style={{ marginTop:"2.5em" }}> 
                  {(document.node.language === "English" && document.node.label === "Foil" ) ?
                    <Img fluid={document.node.cover.childImageSharp.fluid} /> : <span />
                  }
                </div>
              ))}
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </section>
    <section className="page-content" style={{ background:"#232323", paddingBottom:"100px" }}>
      <div className="w-container">
        <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
          <div className="w-row">
            <div className="w-col w-col-6 w-col-stack">
              {data.allStrapiProduct.edges.map(document => (
                <div key={document.node.id}> 
                  {(document.node.language === "English" && document.node.label === "Security" ) ?
                    <Img fluid={document.node.cover.childImageSharp.fluid} /> : <span />
                  }
                </div>
              ))}
            </div>
            <div className="w-col w-col-6 w-col-stack">
              {data.allStrapiProduct.edges.map(document => (
                <div key={document.node.id}> 
                  {(document.node.language === "Chinese" && document.node.label === "Security" ) ?
                  <div style={{ color: "#8c8c8c" }}>
                    <ReactMarkdown source={document.node.description} />
                  </div> : <span />}
                </div>
              ))}
              <Link to="/services/" className="link-wrapper big-link w-inline-block" style={{ marginTop: "3em" }}>
                <div className="link-text">了解更多</div>
              </Link>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </section>

    <section className="section">
      <div className="w-container">
        <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
          <h2 style={{ color: "#232323", lineHeight:"1em" }}>運用雷射、透鏡效果於包裝設計、標籤、裝飾、時尚與防偽，獲得更多靈感</h2>
        </ScrollAnimation> 
      </div>
    </section>  

    <section className="section more-padding" style={{ background:"#232323"}}>
      <div className="w-container">
        <h3 className="product-image-title">雷射膜與紙類產品</h3>
        {data.allStrapiPhoto.edges.map(document => (
          <div key={document.node.id}>
          {(document.node.label === "FilmsAndPapers") ?
            <div className="w-col w-col-4 w-col-medium-6 product-img-wrapper">
              <ImageZoom
                zoomMargin={0}
                shouldRespectMaxDimension={true}
                image={{
                  src: document.node.url.publicURL.replace("/upload/", "/upload/c_scale,h_195,q_auto/"),
                  alt: document.node.name,
                  className: 'img',
                }}
                zoomImage={{
                  src: document.node.url.publicURL.replace("/upload/", "/upload/q_auto/"),
                  alt: document.node.name,
                  className: 'enlarged-img',
                }}
              />
            </div> : <span /> }
          </div>
        ))}
      </div>
    </section>

    <section style={{ background:"#232323"}}>
      <div className="w-container">
        <h3 className="product-image-title">燙金類產品</h3>
        {data.allStrapiPhoto.edges.map(document => (
          <div key={document.node.id}>
          {(document.node.label === "Foils") ?
            <div className="w-col w-col-4 w-col-medium-6 product-img-wrapper">
              <ImageZoom
                zoomMargin={0}
                shouldRespectMaxDimension={true}
                image={{
                  src: document.node.url.publicURL.replace("/upload/", "/upload/c_scale,h_195,q_auto/"),
                  alt: document.node.name,
                  className: 'img',
                }}
                zoomImage={{
                  src: document.node.url.publicURL.replace("/upload/", "/upload/q_auto/"),
                  alt: document.node.name,
                  className: 'enlarged-img',
                }}
              />
            </div> : <span /> }
          </div>
        ))}
      </div>
    </section>

    <section className="section more-padding" style={{ background:"#232323"}}>
      <div className="w-container">
        <h3 className="product-image-title">客製化防偽產品</h3>
        {data.allStrapiPhoto.edges.map(document => (
          <div key={document.node.id}>
          {(document.node.label === "Security") ?
            <div className="w-col w-col-4 w-col-medium-6 product-img-wrapper">
              <ImageZoom
                zoomMargin={0}
                shouldRespectMaxDimension={true}
                image={{
                  src: document.node.url.publicURL.replace("/upload/", "/upload/c_scale,h_195,q_auto/"),
                  alt: document.node.name,
                  className: 'img',
                }}
                zoomImage={{
                  src: document.node.url.publicURL.replace("/upload/", "/upload/q_auto/"),
                  alt: document.node.name,
                  className: 'enlarged-img',
                }}
              />
            </div> : <span /> }
          </div>
        ))}
      </div>
    </section>

    <Footer />
  </Layout>
)
export default ProductsPage;

export const pageQuery = graphql`  
  query ProductsQuery {
    allStrapiProduct {
      edges {
        node {
          id
          language
          description
          label
          cover {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allStrapiPhoto(sort: {order: ASC, fields: id}) {
      edges {
        node {
          id
          label
          url {
            publicURL
          }
          name
        }
      }
    }
  }
`
